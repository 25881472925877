import React from "react"
import styled from 'styled-components';

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 4.375vw;
  
  &:nth-child(n + 3) {
    border-bottom: 1px solid #ecdfe0;
  }
`;

const Col = styled.div`
  flex: 1;
  padding: 4vw 0;
  
  &:last-child {
    min-width: 30%;
  }
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Size Guide" />
    <Row>
      <Col><strong>US</strong></Col>
      <Col><strong>UK</strong></Col>
      <Col><strong>EU</strong></Col>
      <Col><strong>GENERAL</strong></Col>
    </Row>
    <Row>
      <Col>0</Col> <Col>4</Col> <Col>32</Col> <Col>XXS</Col>
    </Row>
    <Row>
      <Col>0</Col> <Col>6</Col> <Col>34</Col> <Col>XXS - XS</Col>
    </Row>
    <Row>
      <Col>2-4</Col> <Col>8</Col> <Col>36</Col> <Col>XS - S</Col>
    </Row>
    <Row>
      <Col>4-6</Col> <Col>10</Col> <Col>38</Col> <Col>S - M</Col>
    </Row>
    <Row>
      <Col>8</Col> <Col>12</Col> <Col>40</Col> <Col>M - L</Col>
    </Row>
    <Row>
      <Col>10</Col> <Col>14</Col> <Col>42</Col> <Col>L - XL</Col>
    </Row>
    <Row>
      <Col>12</Col> <Col>16</Col> <Col>44</Col> <Col>XL - XXL</Col>
    </Row>
    <Row>
      <Col>14</Col> <Col>18</Col> <Col>46</Col> <Col>XXL - XXXL</Col>
    </Row>
    <Row>
      <Col>16</Col> <Col>20</Col> <Col>48</Col> <Col>XXXL</Col>
    </Row>
  </Layout>
);

export default IndexPage
